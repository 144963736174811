import React from 'react'
import Layout from '../components/base/Layout'
import SEO from '../components/base/SEO'

const metaData = {
	title: '404 | Tesser',
	description: '',
	keywords: '',
}

function NotFoundPage() {
	return (
		<Layout color="black">
			<SEO metaData={metaData} />
			<h1>404: Not Found</h1>
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</Layout>
	)
}

export default NotFoundPage
